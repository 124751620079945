//@import "./temp.scss"; //need to remove later, Just for initiate project
@import "./abstract/_variables";
@import "./layout/_layout";
@import "./custom.scss"; // custom from bootstrap
@import "./font.scss";
@import "./app.scss";

html {
    font-size: 16px; // This will define the rem multiplier for the entire aplpication;
}

body {
    font-family: 'Kanit', 'Prompt', sans-serif;
    font-weight: 400;
    color: getColor(gray-7);
}

h1 {
    font-size: 2rem;
    font-weight: 500;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.2rem;
}

input {
    font-family: 'Kanit', 'Prompt', sans-serif;
    font-weight: 400;
    color: var(--dark-text);
}