@import "../../scss/abstract/variables.scss";

.react-bootstrap-table {
    .table {
        &> :not(:first-child) {
            border-top: 3px solid var(--lightgrey-1);
        }

        th {
            font-weight: 400;
        }
    }
}