@import "../../../scss/abstract/variables.scss";


.table-row-action {
    &:hover {
        cursor: pointer;

    }
}

.more-menu-a {
    color: getColor(gray-7);

    &:hover {
        color: getColor(gray-7);
    }

}

.more-menu-item {
    svg {
        margin-right: 8px;
    }
}