@use "../../scss/animation.scss";
@import "../../scss/abstract/variables.scss";

$nav-item-height: 3.45rem;

.sidebar {
    z-index: 0;
    color: var(--primary);
    fill: var(--primary);
    background-color: var(--white);
    filter: drop-shadow($shadow-1);
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    animation: fade-in-left 0.4s ease 1;

    // Put center and bottom apart
    flex-direction: column;
    display: flex;
    justify-content: space-between;

    .side__group {
      cursor: pointer;

      &_item {
        margin-left: calc($sidebar-width - 15rem);
        color: var(--primary);
      }

      &_image {
        margin-left: calc($sidebar-width - 19rem);
        fill: var(--primary);
      }   
    }

    .center {        
        .nav {
          margin-top: 4px;
        }
    }

    .bottom {
      font-size: 0.75rem;
      min-width: $sidebar-width;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .side__group, .nav-item {
      white-space: nowrap;
      height: $nav-item-height;
      display: flex;
      align-items: center;
      width: 100%;

      .sidebar-content {
        color: var(--primary); // Specifically overrides Bootstrap styles
        fill: var(--primary); // Specifically overrides Bootstrap styles
      }

      .nav-link {
        width: 100%;
      }

      .icon-mui-svg {
        font-size: 24px;
      }

      .icon-custom-svg {
        width: 24px;
        height: 24px;
      }

      span {
        margin-left: 10px;
        font-size: 1rem;
      }

      &:hover {
        .sidebar-content {
          color: var(--light-purple);
          fill: var(--light-purple);
          transition: ease .5s;
        }
        .icon-mui-svg, .icon-custom-svg {
          animation: swing ease .5s 1 alternate;
        }
      }

      &::after {
        content: '';
        background-color: var(--primary);
        border-radius: 4px;
        width: 0;
        height: $nav-item-height;
        position: absolute;
        transition: ease .4s;
        z-index: -1;
      }

      &.selected {
        &::after {
          width: 100%;
        }

        .sidebar-content, .side__group_image, .side__group_item {
          color: white;
          fill: white;
        }
      }

    }
}