@import '../../scss/abstract/variables';


.budgetReportButtonPanel {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  &__select {
    width: 15vw;
  }

  &__divider {
    margin: 24px 0px;
    border: solid 1px var(--lightgrey-1);
  }

  &__download {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      min-width: 20px;
    }
  }

}