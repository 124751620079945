@import '../abstract/variables';

.budgetReport {
  &__card-layout {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px;
  }  

  &__expandParent {
    background-color: getColor(violet-1);
  }

  .row-expansion-style {
    border: 3px solid getColor(violet-1);
    border-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .expandItem {
      display: flex;
      justify-content: flex-end;
      color: getColor(gray-7);

      &__year {
        display: flex;
        justify-content: flex-end;
        max-width: 191.5px;
        margin-left: 3rem;
      }

      &__budget {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}


