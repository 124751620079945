@import '../../scss/abstract/variables';

.budgetDashboard_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.planned_budget {
  margin-left: 1rem;
  color: var(--primary);
}

.budgetDashboard {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;

  .card {
    height: 160px;
    margin: 0px 14px;
    width: 100%;
    min-width: 240px;
    max-width: 320px;
    box-shadow: $shadow-grey;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-left: 30px;

    .icon-bg {
      width: 48px;
      height: 48px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .total {
      background-color: #EAE8FF;
    }

    .allocated {
      background-color: #FDCED9;
    }

    .remain {
      background-color: #D4F7E2;
    }

    svg {
      width: 30px;
      height: 30px;
    }

    &__amount {
      color: getColor(gray-7);
      font-size: 1.8rem;
    }
  }
}