@import '../scss/abstract/variables';

// Custom Bootstrap Maintheme before the lib is loaded.
$primary: #5864e9;
$secondary: #d2d5f9;
$warning: #ffc457; // yellow-4
$danger: #ff2974; // pink-5
$success: #1fd65f; // light-green7
$info: #55D6FF;

$button-shadow-warning: 0px 2px 15px 0px rgba($warning, 0.75);
$button-shadow-danger: 0px 2px 15px 0px rgba($danger, 0.75);
$button-shadow-success: 0px 2px 15px 0px rgba($success, 0.75);
$button-shadow-info: 0px 2px 15px 0px rgba($info, 0.75);

// Import Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
      Write your custom CSS styles which will override bootstrap default style only in this file!

                                                THANK YOU
    ==============================================================================================
*/

body {
    .modal-header {
        border-bottom: none;
        padding: 1.5rem;
    }

    .modal-body {
        padding: 1.5rem;
    }

    .modal-footer {
        border-top: none;
        padding: 1rem;
    }

    .modal-content {
        top: 10vh;
        border: none;
        box-shadow: 0 0.5rem 1rem rgba(#000, .15);
    }
}

/*
========== Buttons ==========
*/

body .btn {
    &:focus {
        box-shadow: none;
    }
    min-width: 100px;
}

body .table {
    color: getColor(gray-7);
}

// Overrides BS theme color (Success & Danger)
.btn {

    // On Primary Button Pressed
    &-primary, &-outline-primary {
        &:active {
            animation: button-pressed .05s 1 forwards;
        }
    }

    &-success, &-danger, &-warning {
        box-shadow: $shadow-grey;

        &:disabled {
            background-color: var(--lightgrey-1);
            border-color: var(--lightgrey-1);
            box-shadow: none;
            color: var(--white);
        }
    }

    &-outline-primary, &-outline-danger, &-outline-success, &-outline-warning, &-outline-secondary {
        box-shadow: $shadow-grey;

        &:disabled {
            color: var(--lightgrey-1);
            box-shadow: none;
            border-color: var(--lightgrey-1);
        }
    }

    &-success, &-danger {
        color: white;
        &:hover, &:active, &:focus {
            color: white;
        }
    }

    &-outline-primary, &-outline-success, &-outline-danger {
        &:hover {
            color: white;
        }
    }

    &:hover,
    &:active,
    &:focus {
        &.btn-warning {
            box-shadow: $button-shadow-warning;
            transition: ease .5s;
        }

        &.btn-danger {
            box-shadow: $button-shadow-danger;
            transition: ease .5s;
        }

        &.btn-success {
            box-shadow: $button-shadow-success;
            transition: ease .5s;
        }
    }

}

body .btn-primary, body .btn-secondary {
    &:focus {
        box-shadow: none;

        &:active {
            box-shadow: 0px 0px 0px 3px rgba(138, 112, 244, 0.4);
        }
    }
}

body .btn-primary {
    color: var(--lightwhite-primary);
    background-color: var(--primary);
    border-radius: 5px;
    border: none;
    box-shadow: $button-shadow;

    &:hover, &:focus {
        color: white;
        background-color: var(--dark-purple);
        box-shadow: $button-shadow-large;

        &:active {
            background-color: var(--dark-purple2);
        }
    }

    &:disabled {
        color: white;
        background-color: var(--lightgrey-1);
        box-shadow: none;
    }
}

body .btn-secondary {
    color: var(--primary);
    background-color: white;
    border-radius: 5px;
    border: none;
    box-shadow: $shadow-grey;

    &:hover, &:focus {
        color: var(--primary);
        background-color: white;
        box-shadow: $button-shadow-large;

        &:active {
            color: var(--dark-purple2);
        }
    }

    &:disabled {
        color: var(--lightgrey-1);
        background-color: white;
    }
}

/*
========== Dropdowns ==========
*/
body .dropdown-item {
    &:hover {
        background-color: var(--light-purple2);
    }
}