@import '../../scss/abstract/variables';

.table-column {
  word-wrap: break-word;
}

.id-column {
  width: 10px;
}

td {
  .status-button-wrapper {
    color: var(--white);
    width: 120px;

    .danger {
      background-color: var(--invalid);
      border-radius: 60px;
      width: 100%;
      padding: 5px 5px;
      cursor: default;
    }

    .success {
      background-color: getColor(light-green-7);
      border-radius: 60px;
      width: 100%;
      padding: 5px 5px;
      cursor: default;
    }
  }
}


