@import '../../scss/abstract/variables';

.navbar {
    z-index: 1;
    color: var(--white);
    height: $navbar-height;
    background: var(--gradient-main);
    box-shadow: $shadow-grey;

    .wave {
        z-index: 2;
        position: absolute;
        fill: rgba(255, 255, 255, 0.6);
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100vw;
        z-index: 3;

        &__left {
            display: flex; // Flex inside left container
            align-items: center;
            justify-content: space-evenly;

            .hamburger-menu {
                min-width: 32px;

                svg {
                    fill: var(--lightwhite-primary);
                    width: 24px;
                    height: 24px;

                    &:hover, &:active {
                        fill: var(--primary);
                        color: var(--primary);
                    }
                }
            }

            h3 {
                margin-left: 10px;
                letter-spacing: 0.5px;
                text-shadow: $shadow-grey;
            }
        }

        &__center {
            // Nothing yet
        }

        &__right {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-right: 1.5rem;
            
            #budget-year-ddl {
                margin-right: 10px;
            }
        }
    }

    .btn {
        .icon {
            fill: var(--black);
            height: 24px;
            width: 24px;

            &:hover, &:active{
                fill: var(--primary);
                color: var(--primary);
            }

            &__user {
                height: 24px;
                width: 24px;
            }
        }
    }

    .item {
        margin-right: 4rem;
        cursor: pointer;
    }
}

.user-dropdown {
    text-decoration: none;
    margin-left: 1rem;
    color: var(--white);
}

.user-dropdown:hover {
    color: var(--white);
}

